import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Buffer } from "buffer/";

const CLIENT_ID = "kdslh894736JHUET";
const PROVIDER = "auth0";
const LANG = "it";

let BASEURL = "";

// const BASEURL_IDENTITY = "http://accessocasa.ddns.net:15003";
// const BASEURL_API = "http://accessocasa.ddns.net:15002";
// const BASEURL_UPLOAD = "http://accessocasa.ddns.net:5003";
// const BASEURL_ENCODE = "http://accessocasa.ddns.net:62036";

const BASEURL_IDENTITY = process.env.VUE_APP_BASEURL_IDENTITY;
const BASEURL_API = process.env.VUE_APP_BASEURL_API;
const BASEURL_UPLOAD = process.env.VUE_APP_BASEURL_UPLOAD;
const BASEURL_ENCODE = process.env.VUE_APP_BASEURL_ENCODE;

let apiClientAxios = null;

export default {
  getTokenObj() {
    return store.getters.getToken;
  },
  createAxiosHeaders(token = "") {
    BASEURL = BASEURL_API;
    let apiAxios = null;
    if (token == "") {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return apiAxios;
    } else {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      return apiAxios;
    }
  },
  createDubHeaders(token = "") {
    BASEURL = BASEURL_ENCODE;
    let apiAxios = null;
    if (token == "") {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return apiAxios;
    } else {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      return apiAxios;
    }
  },
  createAxiosHeadersLogin(token = "") {
    BASEURL = BASEURL_IDENTITY;
    let apiAxios = null;
    if (token == "") {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return apiAxios;
    } else {
      apiAxios = axios.create({
        baseURL: BASEURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      return apiAxios;
    }
  },
  createOctectHeader(token) {
    BASEURL = BASEURL_UPLOAD;
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        "x-file-name": "test_file.mp4",
        "x-file-lang": "en",
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + token,
      },
    });
    return apiAxios;
  },
  createAudioHeader(token) {
    BASEURL = BASEURL_UPLOAD;
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + token,
      },
    });
    return apiAxios;
  },
  createBinaryHeader(token) {
    BASEURL = BASEURL_API;
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
    return apiAxios;
  },
  createEncodeHeaders(token = "") {
    BASEURL = BASEURL_ENCODE;
    console.warn("++ BASEURL_ENCODE:", BASEURL_ENCODE);
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return apiAxios;
  },
  createEncodeAudioHeaders(token = "", language = "all") {
    BASEURL = BASEURL_ENCODE;
    console.warn("++ AUDIO BASEURL_ENCODE, language:", BASEURL_ENCODE, language);
    let apiAxios = null;
    apiAxios = axios.create({
      baseURL: BASEURL,
      headers: {
        "x-file-lang": language,
        Authorization: "Bearer " + token,
      },
    });
    return apiAxios;
  },
  //** Login User **
  async loginUser(userName, password) {
    apiClientAxios = this.createAxiosHeadersLogin();
    return await apiClientAxios
      .post("/oauth/LoginUser", {
        metadata: {},
        lang: LANG,
        clientid: CLIENT_ID,
        userName: userName,
        password: password,
      })
      .then(
        (response) => {
          console.log("API loginUser >> response.status >> ", response.status);
          console.log("API loginUser >> response.data >> ", response.data);
          return response;
        },
        (error) => {
          console.error("API loginUser >> error.response.data >>", error.response.data);
          console.error(
            "API loginUser >> error.response.status >>",
            error.response.status
          );
          return error.response;
        }
      );
  },
  //** Refresh Token **
  async refreshToken() {
    let token = store.getters.getToken;
    if (!token) return false;
    console.log("API refreshToken >> is refreshing");
    console.log("API refreshToken >> token", token);
    apiClientAxios = this.createAxiosHeadersLogin();
    return await apiClientAxios
      .post("/oauth/RefreshToken", {
        clientID: CLIENT_ID,
        provider: PROVIDER,
        refreshToken: token.refreshToken,
        actualToken: token.token,
      })
      .then(
        (response) => {
          console.log("API refreshToken >> token is refreshed >>", response.data);
          store.dispatch("setToken", {
            result: response,
            refresh: true,
          });
          return true;
        },
        (error) => {
          console.error(error);
          console.error("API refreshToken >> error >>", error);
          // store.dispatch("clearToken", {});
          // this.$router.push({ name: "view-login" });
          return false;
        }
      );
  },
  //** Check Token **
  async checkToken() {
    store.dispatch("loadLocalToken");
    const localTokenObj = store.getters.getToken;
    const isLogged = store.getters.getUserIsLogged;
    console.log("EventService checkToken >> load local token >>", localTokenObj);
    console.log("EventService checkToken >> isLogged >>", isLogged);

    if (localTokenObj != null && isLogged) {
      //if user is logged and token obj exist
      if (localTokenObj != null) {
        console.log("EventService checkToken >> store.getters.checkTokenTime");
        if (store.getters.checkTokenTime) {
          console.log("EventService checkTokenTime >> true");
          return true;
        } else {
          console.log("EventService checkTokenTime >> false");
          console.log("EventService checkTokenTime >> refreshToken()");
          return await this.refreshToken();
        }
      }
      return false;
    } else {
      if (this.isResetPassword) {
        console.log("#EventService checkToken >> is reset password page");
        return;
      } else {
        console.log("#EventService checkToken >> clearToken");
        store.dispatch("clearToken").then(() => { });
        console.log("#EventService >> push view-login");
        router.push({ name: "view-login" });
        return false;
      }
    }
  },
  isResetPassword() {
    let path = window.location.href;
    let result = path.includes("resetpassword");
    if (result) {
      return true;
    } else {
      return false;
    }
  },
  //** Get Me From Token **
  async getMeFromToken(token) {
    apiClientAxios = this.createAxiosHeaders(token);
    return await apiClientAxios.get("/api/Users/me").then(
      (response) => {
        console.log("API getMeFromToken >>", response);
        return response;
      },
      (error) => {
        console.error("API getMeFromToken >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Attributes Categories **
  async getCategories(token, sort = 0) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .get("/console/Category/categories", {
        params: { page: 0, size: 0, sort: sort },
      })
      .then(
        (response) => {
          console.log("API getCategories >>", response);
          console.log("API getCategories data >>", response.data);

          //store all categories
          store.dispatch("setCategories", response.data);

          return response;
        },
        (error) => {
          console.error("API getCategories >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Edit/Add Category **
  async editAddCategory(token, bodyObj) {
    console.warn(typeof bodyObj, bodyObj);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.post("/console/Category/categories", bodyObj).then(
      (response) => {
        console.log("API editAddCategory >>", response);
        console.log("API editAddCategory data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API editAddCategory >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete Category **
  async deleteCategory(token, id) {
    console.warn("++ id delete category", id);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.delete("/console/Category/categories/" + id).then(
      (response) => {
        console.log("API deleteCategory >>", response);
        console.log("API deleteCategory data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API deleteCategory >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Attributes Tags **
  async getTags(token, sort = 0) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .get("/console/Category/tags", {
        params: { page: 0, size: 0, sort: sort },
      })
      .then(
        (response) => {
          console.log("API getTags >>", response);
          console.log("API getTags data >>", response.data);

          //store all tags
          store.dispatch("setTags", response.data);

          return response;
        },
        (error) => {
          console.error("API getTags >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Edit/Add Tag **
  async editAddTag(token, bodyObj) {
    console.warn(typeof bodyObj, bodyObj);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.post("/console/Category/tags", bodyObj).then(
      (response) => {
        console.log("API editAddTag >>", response);
        console.log("API editAddTag data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API editAddTag >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete Tag **
  async deleteTag(token, id) {
    console.warn("++ id delete tag", id);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.delete("/console/Category/tags/" + id).then(
      (response) => {
        console.log("API deleteTag >>", response);
        console.log("API deleteTag data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API deleteTag >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Attributes Areas **
  async getAreas(token, sort = 0) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .get("/console/Category/areas", {
        params: { page: 0, size: 0, sort: sort },
      })
      .then(
        (response) => {
          console.log("API getAreas >>", response);
          console.log("API getAreas data >>", response.data);

          //store all areas
          store.dispatch("setAreas", response.data);

          return response;
        },
        (error) => {
          console.error("API getAreas >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Edit/Add Area **
  async editAddArea(token, bodyObj) {
    console.warn(typeof bodyObj, bodyObj);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.post("/console/Category/areas", bodyObj).then(
      (response) => {
        console.log("API editAddArea >>", response);
        console.log("API editAddArea data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API editAddArea >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete Tag **
  async deleteArea(token, id) {
    console.warn("++ id delete area", id);
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.delete("/console/Category/areas/" + id).then(
      (response) => {
        console.log("API deleteArea >>", response);
        console.log("API deleteArea data >>", response.data);
        return response;
      },
      (error) => {
        console.error("API deleteArea >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get All Videos Console **
  async getAllVideosConsole(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    console.log("** options", options);
    return await apiClientAxios
      .get("/console/VideosConsole/getallvideos", {
        params: {
          page: options.page,
          size: options.size,
          sort: options.sort,
          category: options.category,
        },
      })
      .then(
        (response) => {
          console.log("API getAllVideosConsole >>", response);
          return response;
        },
        (error) => {
          console.error("API getAllVideosConsole >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get All Jobs Archive **
  async getAllJobsArchive(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    console.log("** options", options);
    return await apiClientAxios
      .get("/console/EncodingJobs/getalljobs", {
        params: {
          page: options.page,
          size: options.size,
        },
      })
      .then(
        (response) => {
          console.log("API getAllJobsArchive >>", response);
          return response;
        },
        (error) => {
          console.error("API getAllJobsArchive >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get All Lessons **
  async getAllLessons(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    console.log("** options", options);
    return await apiClientAxios
      .get("/console/Lesson/getalllessons", {
        params: {
          page: options.page,
          size: options.size,
          sort: options.sort,
          category: options.category,
        },
      })
      .then(
        (response) => {
          console.log("API getAllLessons >>", response);
          return response;
        },
        (error) => {
          console.error("API getAllLessons >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get Searched Videos **
  async getSearchedConsoleVideos(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    let data = JSON.stringify({
      text: options.textToSearch,
      page: options.page,
      size: options.size,
      sort: options.sort,
      category: options.category,
    });
    console.warn("++ search data:", data);
    return await apiClientAxios.post("/console/VideosConsole/search", data).then(
      (response) => {
        console.log("API getSearchedConsoleVideos >>", response);
        return response;
      },
      (error) => {
        console.error("API getSearchedConsoleVideos >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Video **
  async getVideo(videoId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/VideosConsole/getvideo/" + videoId).then(
      (response) => {
        console.log("API getVideo >>", response);
        return response;
      },
      (error) => {
        console.error("API getVideo >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Lesson **
  async getLesson(lessonId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/Lesson/getlesson/" + lessonId).then(
      (response) => {
        console.log("API getLesson >>", response);
        return response;
      },
      (error) => {
        console.error("API getLesson >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Lesson for Embed **
  async getLessonForEmbed(lessonId, bodyObj, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .post("/console/Lesson/getlessonforembed/" + lessonId, bodyObj)
      .then(
        (response) => {
          console.log("API getLessonForEmbed >>", response);
          return response;
        },
        (error) => {
          console.error("API getLessonForEmbed >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get Offline Lesson **
  async getOfflineLesson(lessonId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/Lesson/getofflinelesson/" + lessonId).then(
      (response) => {
        console.log("API getOfflineLesson >>", response);
        return response;
      },
      (error) => {
        console.error("API getOfflineLesson >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Generate Offline Lesson **
  async generateOfflineLesson(lessonId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/Lesson/ziplesson/" + lessonId).then(
      (response) => {
        console.log("API generateOfflineLesson >>", response);
        return response;
      },
      (error) => {
        console.error("API generateOfflineLesson >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Upload File **
  async uploadFile(fileName) {
    const isTokenValid = await this.checkToken();
    if (!isTokenValid) {
      return false;
    }
    apiClientAxios = this.createOctectHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/Files/Upload", fileName, {
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          console.log("Upload Progress: " + this.uploadPercentage + "%");
        }.bind(this),
      })
      .then(
        (response) => {
          console.log("API uploadFile >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadFile >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Delete Attachment **
  async deleteAttachment(options, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    const videoId = options.videoId;
    let attachmentname = options.attachmentname;
    let attachmentname_base64 = Buffer.from(attachmentname).toString("base64");
    console.log("** attachmentname", attachmentname);
    console.log("** attachmentname_base64", attachmentname_base64);
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete(
        "/console/VideosConsole/attachments" + "/" + videoId + "/" + attachmentname_base64
      )
      .then(
        (response) => {
          console.log("API deleteAttachment >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteAttachment >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Delete Subtitle **
  async deleteSubtitle(options, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    const videoId = options.videoId;
    let subtitlename = options.subtitlename;
    let subtitlename_base64 = Buffer.from(subtitlename).toString("base64");
    console.log("** subtitlename", subtitlename);
    console.log("** subtitlename_base64", subtitlename_base64);
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete(
        "/console/VideosConsole/subtitles" + "/" + videoId + "/" + subtitlename_base64
      )
      .then(
        (response) => {
          console.log("API deleteSubtitle >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteSubtitle >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Delete Audio **
  async deleteAudio(options, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    const videoId = options.videoId;
    let subtitlename = options.subtitlename;
    let subtitlename_base64 = Buffer.from(subtitlename).toString("base64");
    console.log("** subtitlename", subtitlename);
    console.log("** subtitlename_base64", subtitlename_base64);
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete(
        "/console/VideosConsole/subtitles" + "/" + videoId + "/" + subtitlename_base64
      )
      .then(
        (response) => {
          console.log("API deleteAudio >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteAudio >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Delete Thumbnail **
  async deleteThumbnail(options, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    const videoId = options.videoId;
    let thumbName = options.thumbName;
    let thumbName_base64 = Buffer.from(thumbName).toString("base64");
    console.log("** thumbName", thumbName);
    console.log("** thumbName_base64", thumbName_base64);
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete("/console/VideosConsole/thumb" + "/" + videoId + "/" + thumbName_base64)
      .then(
        (response) => {
          console.log("API deleteThumbnail >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteThumbnail >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Delete Video **
  async deleteVideo(videoId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete("/console/VideosConsole/deletevideo" + "/" + videoId)
      .then(
        (response) => {
          console.log("API deleteVideo >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteVideo >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Upload Thumbnail **
  async uploadThumbnail(formData, videoId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    //apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/console/VideosConsole/thumb" + "/" + videoId, formData)
      .then(
        (response) => {
          console.log("API uploadThumbnail >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadThumbnail >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Upload Thumbnail Base64 **
  async uploadThumbnailBase64(imageObj, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.post("/console/VideosConsole/thumb64", imageObj).then(
      (response) => {
        console.log("API uploadThumbnailBase64 >>", response);
        return response;
      },
      (error) => {
        console.error("API uploadThumbnailBase64 >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Upload File Attachment **
  async uploadAttachment(formData, videoId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    //apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/console/VideosConsole/attachments" + "/" + videoId, formData)
      .then(
        (response) => {
          console.log("API uploadAttachment >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadAttachment >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Upload File Subtitle **
  async uploadSubtitle(formData, videoId, language, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    //apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    console.log(formData);
    return await apiClientAxios
      .post("/console/VideosConsole/subtitles" + "/" + videoId + "/" + language, formData)
      .then(
        (response) => {
          console.log("API uploadSubtitle >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadSubtitle >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Upload File Audio **
  async uploadAudio(formData, videoId, language, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    //apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/console/VideosConsole/subtitles" + "/" + videoId + "/" + language, formData)
      .then(
        (response) => {
          console.log("API uploadAudio >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadAudio >> error >>", error.response);
          return error.response;
        }
      );
  },

  // ** Update Video Object **
  async updateVideoObj(videoObj, videoId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .put("/console/VideosConsole/updateVideo/" + videoId, videoObj)
      .then(
        (response) => {
          console.log("API updateVideoObj >>", response);
          return response;
        },
        (error) => {
          console.error("API updateVideoObj >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Update Lesson Object **
  async updateLessonObj(lessonObj, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.put("/console/Lesson", lessonObj).then(
      (response) => {
        console.log("API updateLessonObj >>", response);
        return response;
      },
      (error) => {
        console.error("API updateLessonObj >> error >>", error.response);
        return error.response;
      }
    );
  },
  // ** Create Lesson Object **
  async createLessonObj(lessonObj, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.post("/console/Lesson", lessonObj).then(
      (response) => {
        console.log("API createLessonObj >>", response);
        return response;
      },
      (error) => {
        console.error("API createLessonObj >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete Lesson **
  async deleteLesson(lessonId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete("/console/Lesson/deletelesson" + "/" + lessonId)
      .then(
        (response) => {
          console.log("API deleteLesson >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteLesson >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Set marker **
  async setMarker(markerObj, videoId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .put("/console/VideosConsole/setmarker/" + videoId, markerObj)
      .then(
        (response) => {
          console.log("API setMarker >>", response);
          return response;
        },
        (error) => {
          console.error("API setMarker >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Unset Marker **
  async unsetMarker(options, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    const videoId = options.videoId;
    let thumbName = options.thumbName;
    let thumbName_base64 = Buffer.from(thumbName).toString("base64");
    console.log("** thumbName", thumbName);
    console.log("** thumbName_base64", thumbName_base64);
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete("/console/VideosConsole/thumb" + "/" + videoId + "/" + thumbName_base64)
      .then(
        (response) => {
          console.log("API deleteThumbnail >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteThumbnail >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Encoding Job ID **
  async encodingJobID(encodingJobid, language, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    console.warn(
      "++ encodingJobID:",
      BASEURL_ENCODE + "/api/Encoder/Encode/" + encodingJobid + "/" + language
    );
    return await apiClientAxios
      .put("/api/Encoder/Encode/" + encodingJobid + "/" + language)
      .then(
        (response) => {
          console.log("API encodingJobID >>", response);
          return response;
        },
        (error) => {
          console.error("API encodingJobID >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Encoding Audio Job ID **
  async encodingAudioJobID(encodingJobid, language, token = "") {
    console.log("++ encoding audio language:", language);
    console.log();
    if (token == "") {
      apiClientAxios = this.createEncodeAudioHeaders();
    } else {
      apiClientAxios = this.createEncodeAudioHeaders(token, language);
    }
    return await apiClientAxios.put("/api/Encoder/AddAudioTrack/" + encodingJobid).then(
      (response) => {
        console.log("API encodingJobID >>", response);
        return response;
      },
      (error) => {
        console.error("API encodingJobID >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Test socket **
  async testSocket(token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    return await apiClientAxios.get("/api/Encoder/testsocket").then(
      (response) => {
        console.log("API testSocket >>", response);
        return response;
      },
      (error) => {
        console.error("API testSocket >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Settings Thumbnail **
  async getSettingsThumbnails(token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/SettingsConsole/thumbnail").then(
      (response) => {
        console.log("API getSettingsThumbnails >>", response);
        return response;
      },
      (error) => {
        console.error("API getSettingsThumbnails >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Set Settings Thumbnail **
  async putSettingsThumbnails(bodyObj, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.put("/console/SettingsConsole/thumbnail", bodyObj).then(
      (response) => {
        console.log("API putSettingsThumbnails >>", response);
        return response;
      },
      (error) => {
        console.error("API putSettingsThumbnails >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get Settings Protection Rules **
  async getSettingsProtectionRule(token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/SettingsConsole/protection").then(
      (response) => {
        console.log("API getSettingsProtectionRule >>", response);
        return response;
      },
      (error) => {
        console.error("API getSettingsProtectionRule >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Set Settings Protection Rule **
  async putSettingsProtectionRule(bodyObj, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.put("/console/SettingsConsole/protection", bodyObj).then(
      (response) => {
        console.log("API putSettingsProtectionRule >>", response);
        return response;
      },
      (error) => {
        console.error("API putSettingsProtectionRule >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get All Images Console **
  async getAllImagesConsole(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    console.log("** options", options);
    return await apiClientAxios
      .get("/console/ImagesConsole/getallimages", {
        params: {
          page: options.page,
          size: options.size,
          sort: options.sort,
          category: options.category,
        },
      })
      .then(
        (response) => {
          console.log("API getAllImagesConsole >>", response);
          return response;
        },
        (error) => {
          console.error("API getAllImagesConsole >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get Image **
  async getImage(imageId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.get("/console/ImagesConsole/getimage/" + imageId).then(
      (response) => {
        console.log("API getImage >>", response);
        return response;
      },
      (error) => {
        console.error("API getImage >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete Image **
  async deleteImage(imageId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    // apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .delete("/console/ImagesConsole/deleteimage" + "/" + imageId)
      .then(
        (response) => {
          console.log("API deleteImage >>", response);
          return response;
        },
        (error) => {
          console.error("API deleteImage >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Encoding Job Image ID **
  async encodingJobImageID(encodingJobid, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    return await apiClientAxios.put("/api/Encoder/EncodeImage/" + encodingJobid).then(
      (response) => {
        console.log("API encodingJobImageID >>", response);
        return response;
      },
      (error) => {
        console.error("API encodingJobImageID >> error >>", error.response);
        return error.response;
      }
    );
  },
  // ** Update Image Object **
  async updateImageObj(imageObj, imageId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .put("/console/ImagesConsole/updateImage/" + imageId, imageObj)
      .then(
        (response) => {
          console.log("API updateImageObj >>", response);
          return response;
        },
        (error) => {
          console.error("API updateImageObj >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Upload File Attachment Image **
  async uploadFileAttachmentImage(formData, imageId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/console/ImagesConsole/attachments" + "/" + imageId, formData)
      .then(
        (response) => {
          console.log("API uploadFileAttachmentImage >>", response);
          return response;
        },
        (error) => {
          console.error("API uploadFileAttachmentImage >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get Searched Images **
  async getSearchedConsoleImages(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    let data = JSON.stringify({
      text: options.textToSearch,
      page: options.page,
      size: options.size,
      sort: options.sort,
      category: options.category,
    });
    console.warn("++ search data:", data);
    return await apiClientAxios.post("/console/ImagesConsole/search", data).then(
      (response) => {
        console.log("API getSearchedConsoleImages >>", response);
        return response;
      },
      (error) => {
        console.error("API getSearchedConsoleImages >> error >>", error.response);
        return error.response;
      }
    );
  },
  // ** Save New Resolution **
  async saveNewResolution(imageObj, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .post("/console/ImagesConsole/addResolution", imageObj)
      .then(
        (response) => {
          console.log("API saveNewResolution >>", response);
          return response;
        },
        (error) => {
          console.error("API saveNewResolution >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Get Image Base64  **
  async getImageBase64(imageObj, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .post("/console/ImagesConsole/getImageBase64", imageObj)
      .then(
        (response) => {
          console.log("API getImageBase64 >>", response);
          return response;
        },
        (error) => {
          console.error("API getImageBase64 >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Encoding VTT Job ID **
  async encodingVTTJobID(encodingJobid, language, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    console.warn(
      "++ encodingVTTJobID:",
      BASEURL_ENCODE + "/api/Encoder/EncodeVtt/" + encodingJobid + "/" + language
    );
    return await apiClientAxios
      .put("/api/Encoder/EncodeVtt/" + encodingJobid + "/" + language)
      .then(
        (response) => {
          console.log("API encodingVTTJobID >>", response);
          return response;
        },
        (error) => {
          console.error("API encodingVTTJobID >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Speech To Text **
  async speechToText(videoId, language, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    //apiClientAxios = this.createAxiosHeaders(store.getters.getToken.token);
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post("/console/VideosConsole/speechtotext" + "/" + videoId + "/" + language)
      .then(
        (response) => {
          console.log("API speechToText >>", response);
          return response;
        },
        (error) => {
          console.error("API speechToText >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Encoding STT Job ID **
  async encodingSTTJobID(encodingJobid, language, sttLang, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    console.warn(
      "++ encodingSTTJobID:",
      BASEURL_ENCODE +
      "/api/Encoder/EncodeStt/" +
      encodingJobid +
      "/" +
      language +
      "/" +
      sttLang
    );
    return await apiClientAxios
      .put("/api/Encoder/EncodeStt/" + encodingJobid + "/" + language + "/" + sttLang)
      .then(
        (response) => {
          console.log("API encodingSTTJobID >>", response);
          return response;
        },
        (error) => {
          console.error("API encodingSTTJobID >> error >>", error.response);
          return error.response;
        }
      );
  },
  // ** Check Encoding STT Job ID **
  async checkEncodingSTTJobID(encodingJobid, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    return await apiClientAxios.get("/api/Encoder/CheckJobStatus/" + encodingJobid).then(
      (response) => {
        console.log("API checkEncodingSTTJobID >>", response);
        return response;
      },
      (error) => {
        console.error("API checkEncodingSTTJobID >> error >>", error.response);
        return error.response;
      }
    );
  },
  // ** Check Encoding DUB Job ID **
  async checkEncodingDUBJobID(encodingJobid, token = "") {
    if (token == "") {
      apiClientAxios = this.createEncodeHeaders();
    } else {
      apiClientAxios = this.createEncodeHeaders(token);
    }
    return await apiClientAxios.get("/api/Encoder/CheckJobStatus/" + encodingJobid).then(
      (response) => {
        console.log("API checkEncodingDUBJobID >>", response);
        return response;
      },
      (error) => {
        console.error("API checkEncodingDUBJobID >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Send VTT to translate **
  async sendVTTToTranslate(videoId, sourceLang, destlang, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    apiClientAxios = this.createBinaryHeader(store.getters.getToken.token);
    return await apiClientAxios
      .post(
        "/console/VideosConsole/translatesubtitles" +
        "/" +
        videoId +
        "/" +
        sourceLang +
        "/" +
        destlang
      )
      .then(
        (response) => {
          console.log("API sendVTTToTranslate >>", response);
          return response;
        },
        (error) => {
          console.error("API sendVTTToTranslate >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Send language to DUB **
  async sendlanguageToDub(videoId, sourceLang, destlang, token = "") {
    if (token == "") {
      apiClientAxios = this.createDubHeaders();
    } else {
      apiClientAxios = this.createDubHeaders(token);
    }
    apiClientAxios = this.createEncodeHeaders(store.getters.getToken.token);
    return await apiClientAxios
      .put(
        "/api/Encoder/VideoDubbing" +
        "/" +
        videoId +
        "/" +
        sourceLang +
        "/" +
        destlang
      )
      .then(
        (response) => {
          console.log("API sendlanguageToDub >>", response);
          return response;
        },
        (error) => {
          console.error("API sendlanguageToDub >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Get All Users **
  async getAllUsers(options, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    console.log("** options", options);
    return await apiClientAxios
      .get("/oauth/admin/Users", {
        params: {
          page: options.page,
          size: options.size,
          clientid: CLIENT_ID,
          text: options.text,
          isAdmin: options.isAdmin,
        },
      })
      .then(
        (response) => {
          console.log("API getAllUsers >>", response);
          return response;
        },
        (error) => {
          console.error("API getAllUsers >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Add New User **
  async addNewUser(body, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios.post("/oauth/CreateUser", body).then(
      (response) => {
        console.log("API addNewUser >>", response);
        return response;
      },
      (error) => {
        console.error("API addNewUser >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Delete User **
  async deleteUser(userId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios.delete("/oauth/admin/Users" + "/" + userId).then(
      (response) => {
        console.log("API deleteUser >>", response);
        return response;
      },
      (error) => {
        console.error("API deleteUser >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Get User **
  async getUser(userId, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios
      .get("/oauth/admin/Users/GetSingleUser" + "?userID=" + userId)
      .then(
        (response) => {
          console.log("API getUser >>", response);
          return response;
        },
        (error) => {
          console.error("API getUser >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** get User permission **
  async getUsersPermission(userId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios
      .put("/console/UsersConsole/getpermissions/" + userId)
      .then(
        (response) => {
          console.log("API put user permissions >>", response);
          return response;
        },
        (error) => {
          console.error("API put user permissions >> error >>", error.response);
          return error.response;
        }
      );
  },

  //** Update User permission **
  async updateUserCategories(bodyObj, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeaders();
    } else {
      apiClientAxios = this.createAxiosHeaders(token);
    }
    return await apiClientAxios.put("/console/UsersConsole/setpermission", bodyObj).then(
      (response) => {
        console.log("API put users console >>", response);
        return response;
      },
      (error) => {
        console.error("API put users console >> error >>", error.response);
        return error.response;
      }
    );
  },

  //** Update User Metadata **
  async updateUserMetadata(userId, body, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios
      .put("/oauth/admin/Users/UpdateUserMetadata" + "/" + userId, body)
      .then(
        (response) => {
          console.log("API updateUserMetadata >>", response);
          return response;
        },
        (error) => {
          console.error("API updateUserMetadata >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Update User isAdmin **
  async updateUserIsAdmin(body, token = "") {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios.put("/oauth/admin/Users", body).then(
      (response) => {
        console.log("API updateUserIsAdmin >>", response);
        return response;
      },
      (error) => {
        console.error("API updateUserIsAdmin >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Send Email Reset Password **
  async sendEmailResetPassword(email) {
    apiClientAxios = this.createAxiosHeadersLogin();
    return await apiClientAxios
      .post("/oauth/ResetPassword/SendEmail", {
        clientid: CLIENT_ID,
        email: email,
      })
      .then(
        (response) => {
          console.log(
            "API sendEmailResetPassword >> response.status >> ",
            response.status
          );
          console.log("API sendEmailResetPassword >> response.data >> ", response.data);
          return response;
        },
        (error) => {
          console.error(
            "API sendEmailResetPassword >> error.response.data >>",
            error.response.data
          );
          console.error(
            "API sendEmailResetPassword >> error.response.status >>",
            error.response.status
          );
          return error.response;
        }
      );
  },
  //** Update Password **
  async updatePassword(objPasswords, userId, token) {
    if (token == "") {
      apiClientAxios = this.createAxiosHeadersLogin();
    } else {
      apiClientAxios = this.createAxiosHeadersLogin(token);
    }
    return await apiClientAxios
      .put("/oauth/admin/Users/UpdatePassword" + "/" + userId, objPasswords)
      .then(
        (response) => {
          console.log("API updatePassword >>", response);
          return response;
        },
        (error) => {
          console.error("API updatePassword >> error >>", error.response);
          return error.response;
        }
      );
  },
  //** Reset Password **
  async resetPassword(objBody) {
    apiClientAxios = this.createAxiosHeadersLogin();
    return await apiClientAxios.post("/oauth/ResetPassword", objBody).then(
      (response) => {
        console.log("API updatePassword >>", response);
        return response;
      },
      (error) => {
        console.error("API updatePassword >> error >>", error.response);
        return error.response;
      }
    );
  },
  //** Send Email Reminder **
  async sendEmailRemainder(objBody, token) {
    apiClientAxios = this.createAxiosHeadersLogin(token);
    return await apiClientAxios
      .post("/oauth/admin/Users/SendEmailReminder", objBody)
      .then(
        (response) => {
          console.log("API sendEmailRemainder >> response.status >> ", response.status);
          console.log("API sendEmailRemainder >> response.data >> ", response.data);
          return response;
        },
        (error) => {
          console.error(
            "API sendEmailRemainder >> error.response.data >>",
            error.response.data
          );
          console.error(
            "API sendEmailRemainder >> error.response.status >>",
            error.response.status
          );
          return error.response;
        }
      );
  },
};
